<template>
  <div class="flex h-full w-full flex-col">
    <div v-if="alert.pair && alert.exchange && ticker" class="h-full py-2">
      <ChartCoinOverview
        :key="ticker"
        :coin-uids="['dummy_coin']"
        :chart-symbol="ticker"
        :metrics="[{ title: 'Price', id: 'price' }]"
        :metric-selected="chartMetric"
        time-selected="7D"
        :expand-chart-link="`/charts?symbol=${ticker}`"
        :candle-chart-mode="true"
        class="h-full"
        :from-coin="true"
      />
    </div>
    <div class="flex h-full w-full items-center justify-center space-x-2" v-else>
      <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
      <span class="block text-xs">Select a Coin to Visualize</span>
    </div>
  </div>
</template>
<script setup>
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import ChartCoinOverview from '@/components/chart/ChartCoinOverview.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  alert: {
    type: Object,
    default: () => null
  }
});

const chartMetric = ref('price');
const ticker = computed(() => {
  return `CCXT:${props.alert.pair}:exchange:${props.alert.exchange}`;
});
</script>
